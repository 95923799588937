import React, { Fragment, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSlate } from 'slate-react'
import { differenceWith, isEqual, size } from 'lodash'
import { Box, Tooltip, Typography, IconButton } from '@material-ui/core'

import { MoreOptionsMenu } from './components'

import validates from 'helpers/validates'

import theme from 'theme'
import useStyles from './styles'
import constants from 'constants/index'

const Toolbar = ({ controlName }) => {
  const [hiddenGroups, setHiddenGroups] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)

  const classes = useStyles()
  const editor = useSlate()
  const { errors } = useFormContext()

  const handleCloseMenu = () => setAnchorEl(null)

  const handleClick = (format, type) => {
    if (type === 'mark') validates.slateJs.toggleMark(editor, format)
    else if (type === 'block') validates.slateJs.toggleBlock(editor, format)
    handleCloseMenu()
  }

  const filteredGroups = differenceWith(
    constants.slateJs.TOOLBAR_GROUPS,
    hiddenGroups,
    isEqual,
  )

  return (
    <Box
      border={1}
      borderBottom={0}
      borderColor={
        !!errors?.[controlName]
          ? theme.palette.error.main
          : theme.palette.textfield.border
      }
    >
      <Box
        display="flex"
        flexWrap="wrap"
        gridGap={theme.spacing(2)}
        justifyContent="center"
      >
        {filteredGroups?.map((group, index) => (
          <Box key={index} display="flex">
            {group?.map((element, elementIndex) => {
              const Logo = element?.Icon || Fragment
              const isActive =
                element.type === 'mark'
                  ? validates.slateJs.isMarkActive(editor, element.format)
                  : validates.slateJs.isBlockActive(editor, element.format)

              return (
                <Tooltip
                  title={`${element.description} (${element?.shortcutKey})`}
                  key={elementIndex}
                >
                  <IconButton
                    className={classes.iconButton}
                    onMouseDown={(event) => {
                      event.preventDefault()
                      handleClick(element?.format, element?.type)
                    }}
                    style={{
                      color: isActive
                        ? errors?.[controlName]
                          ? theme.palette.error.main
                          : 'black'
                        : 'gray',
                    }}
                  >
                    {validates.slateJs.toolbarTitleLabel(element?.format) ? (
                      <Typography>
                        {validates.slateJs.toolbarTitleLabel(element?.format)}
                      </Typography>
                    ) : (
                      <Logo />
                    )}
                  </IconButton>
                </Tooltip>
              )
            })}
          </Box>
        ))}
        {/* Sera implementado validação na proxima atualização */}
        {size(hiddenGroups) > 0 && (
          <MoreOptionsMenu
            controlName={controlName}
            handleClick={handleClick}
            handleCloseMenu={handleCloseMenu}
            anchorElState={{ anchorEl, setAnchorEl }}
            hiddenGroups={hiddenGroups}
          />
        )}
      </Box>
    </Box>
  )
}

export default Toolbar
