import React from 'react'
import moment from 'moment'
import * as service from 'service'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { TextField, Grid, Box } from '@material-ui/core'

import {
  LoadingFeedback,
  Card,
  Label,
  OptionCheckIncident,
  IncidentViolationType,
} from 'components'
import { DataIncident, DescriptionIncident } from './components'

import usePermissions from 'hooks/usePermissions'
import useFetch from 'hooks/useFetch'

import constants from 'constants/index'
import schema from './schema'
import helpers from 'helpers'

const Simplified = ({ incident, handleSubmit }) => {
  const permissions = usePermissions()
  const { isDpo } = usePermissions()

  const hasEditPermission =
    !incident || permissions.permitted(constants.permissions.INCIDENTS.CREATE)

  const verifyDescription = (value) => {
    return constants.incidents.INCIDENT_VIOLATION_TYPES.find(
      (item) => item?.value === value,
    ).description
  }

  const mountDefaultViolationTypes = () => {
    return (
      incident?.incidentViolationTypes?.map((ViolationTypes) => ({
        value: ViolationTypes?.value,
        name: ViolationTypes?.name,
        description: verifyDescription(ViolationTypes?.value),
      })) || []
    )
  }

  const { response, isLoading } = useFetch(
    service.dponet.incidents.options,
    {
      typeFields: [
        'which_security_measures_adopted',
        'types_sensitive_personal_data_breached',
        'other_type_personal_data_violated',
      ],
    },
    [],
  )

  const {
    handleSubmit: hookFormHandleSubmit,
    errors,
    control,
    watch,
    setValue,
  } = useForm({
    validationSchema: schema,
    defaultValues: {
      incidentDate: incident?.incidentDatetime
        ? moment(incident.incidentDatetime).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      incidentHour: incident?.incidentDatetime
        ? moment(incident.incidentDatetime)
        : moment(),
      incidentViolationTypes: mountDefaultViolationTypes(),
      affectedHolders: incident?.affectedHolders ?? [],
      description: incident?.description ?? '',
      descriptionMeasures: incident?.descriptionMeasures ?? '',
      descriptionRootIncident: incident?.descriptionRootIncident ?? '',
      technicalAdministrativeMeasure:
        incident?.technicalAdministrativeMeasure ?? '',
      legalOpinion: incident?.legalOpinion ?? '',
      nonSensitiveViolationDescription:
        incident?.nonSensitiveViolationDescription ?? '',
      descriptionOtherTechnicalAdministrativeSecurityMeasure:
        incident?.descriptionOtherTechnicalAdministrativeSecurityMeasure ?? '',
      whichSecurityMeasuresAdopted:
        incident?.dataOptionIncidents?.whichSecurityMeasuresAdopted || [],
      typesSensitivePersonalDataBreached:
        incident?.dataOptionIncidents?.typesSensitivePersonalDataBreached || [],
      otherTypePersonalDataViolated:
        incident?.dataOptionIncidents?.otherTypePersonalDataViolated || [],
      whichSecurityMeasuresAdoptedOther: helpers.incidents.otherOption(
        incident?.dataOptionIncidents?.whichSecurityMeasuresAdopted,
      ),
      typesSensitivePersonalDataBreachedOther: helpers.incidents.otherOption(
        incident?.dataOptionIncidents?.typesSensitivePersonalDataBreached,
      ),
      otherTypePersonalDataViolatedOther: helpers.incidents.otherOption(
        incident?.dataOptionIncidents?.otherTypePersonalDataViolated,
      ),
    },
  })

  const onSubmit = async (incidentData) => {
    if (incidentData?.incidentHour) {
      incidentData.incidentHour = moment(
        incidentData.incidentHour,
        'HH:mm:ss',
      ).format('HH:mm')
    }

    if (incidentData?.incidentDate) {
      incidentData.incidentDate = moment(incidentData.incidentDate).format(
        'DD/MM/YYYY',
      )
    }

    if (incidentData?.incidentViolationTypes) {
      incidentData.incidentViolationTypes =
        incidentData?.incidentViolationTypes?.map((violationType) => ({
          id: violationType?.value,
        })) || []
    }

    handleSubmit(incidentData)
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <form
        id="incident-simplified-form"
        onSubmit={hookFormHandleSubmit(onSubmit)}
      >
        <Card title="Impactos do Incidente Sobre os Dados Pessoais">
          <Grid container spacing={1}>
            <IncidentViolationType
              control={control}
              setValue={setValue}
              checkedOptions={mountDefaultViolationTypes()}
            />
            <OptionCheckIncident
              label="Se aplicável, quais os tipos de dados pessoais sensíveis foram violados:"
              control={control}
              options={response?.data?.typesSensitivePersonalDataBreached}
              name="typesSensitivePersonalDataBreached"
              setValue={setValue}
              watch={watch}
              literaName="types_sensitive_personal_data_breached"
              checkedOptions={
                incident?.dataOptionIncidents
                  ?.typesSensitivePersonalDataBreached
              }
            />
            <OptionCheckIncident
              label="Quais os demais tipos  de dados  pessoais violados?"
              control={control}
              options={response?.data?.otherTypePersonalDataViolated}
              name="otherTypePersonalDataViolated"
              other
              setValue={setValue}
              watch={watch}
              literaName="other_type_personal_data_violated"
              checkedOptions={
                incident?.dataOptionIncidents?.otherTypePersonalDataViolated
              }
            />
            <Grid item xs={12}>
              <Box mt={2}>
                <Label title="Descrever os tipos de dados pessoais não sensíveis violados, se cabível:">
                  <Controller
                    as={
                      <TextField
                        type="text"
                        minRows={5}
                        multiline
                        color="primary"
                        variant="outlined"
                        error={!!errors?.nonSensitiveViolationDescription}
                        helperText={
                          errors?.nonSensitiveViolationDescription?.message
                        }
                        fullWidth
                      />
                    }
                    disabled={permissions.isDpo()}
                    control={control}
                    name="nonSensitiveViolationDescription"
                    mode="onBlur"
                  />
                </Label>
              </Box>
            </Grid>
          </Grid>
        </Card>

        <Card title="Formulario de Comunicação de Incidente de Segurança com Dados Pessoais - Simplificado">
          <Grid container spacing={1}>
            <Grid
              container
              spacing={1}
              id={constants.incidents.NEW_INCIDENT_DRIVER_STEP}
            >
              <DataIncident
                errors={errors}
                control={control}
                hasEditPermission={hasEditPermission}
              />
              <Grid item xs={12}>
                <Box mt={2}>
                  <Label title="Descreva, resumidamente, como ocorreu o incidente:">
                    <Controller
                      as={
                        <TextField
                          type="text"
                          minRows={5}
                          multiline
                          color="primary"
                          variant="outlined"
                          error={!!errors.description}
                          helperText={errors?.description?.message}
                          fullWidth
                        />
                      }
                      control={control}
                      disabled={isDpo()}
                      name="description"
                      mode="onBlur"
                    />
                  </Label>
                </Box>
              </Grid>
            </Grid>
            <DescriptionIncident
              errors={errors}
              control={control}
              isDpo={isDpo}
            />
            <OptionCheckIncident
              label="Antes do incidente, quais das seguites medidas de segurança eram adotadas"
              control={control}
              options={response?.data?.whichSecurityMeasuresAdopted}
              checkedOptions={
                incident?.dataOptionIncidents?.whichSecurityMeasuresAdopted
              }
              name="whichSecurityMeasuresAdopted"
              setValue={setValue}
              watch={watch}
              literaName="which_security_measures_adopted"
              other
            />
            <Grid item xs={12}>
              <Box mt={2}>
                <Label title="Descrever as demais medidas de segurança técnicas e administrativas adotadas antes do incidente:">
                  <Controller
                    as={
                      <TextField
                        type="text"
                        minRows={5}
                        multiline
                        color="primary"
                        variant="outlined"
                        error={
                          !!errors?.descriptionOtherTechnicalAdministrativeSecurityMeasure
                        }
                        helperText={
                          errors
                            ?.descriptionOtherTechnicalAdministrativeSecurityMeasure
                            ?.message
                        }
                        fullWidth
                      />
                    }
                    disabled={permissions.isDpo()}
                    control={control}
                    name="descriptionOtherTechnicalAdministrativeSecurityMeasure"
                    mode="onBlur"
                  />
                </Label>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </form>
    </>
  )
}

Simplified.propTypes = {
  incident: PropTypes.object,
  toLinkProcesses: PropTypes.array,
}

Simplified.defaultProps = {
  toLinkProcesses: [],
}

export default Simplified
