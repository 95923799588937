import { sum } from 'lodash'

import palette from 'theme/palette'

import helpers from 'helpers'
import constants from 'constants/index'

import templates from '../../templates'
import components from '../../components'

import { ratio } from '../../helpers'

import { WIDTH as WIDTHPdf, PADDING, GAP } from '../../constants'

const generalInformations = async (
  pdf,
  date,
  GAPStats,
  customStyles,
  isComplete,
  questionControls,
) => {
  const WIDTH = WIDTHPdf(pdf)

  const plansColumStyles = {
    0: {
      cellWidth: WIDTH * 0.3,
      halign: 'left',
      font: 'Roboto-Bold',
    },
  }

  const totalAnswered = sum(
    GAPStats?.questionnaires?.map(
      (questionnaire) => questionnaire?.asweredQuestions || 0,
    ),
  )
  const totalQuestion = sum(
    GAPStats?.questionnaires?.map(
      (questionnaire) => questionnaire?.totalQuestions || 0,
    ),
  )

  const progressPercentage =
    Math.round((totalAnswered / totalQuestion) * 100).toString() + '%'

  const compliance = Math.round(GAPStats?.companyCompliance).toString() + '%'

  templates.chapterCover(
    pdf,
    'VISÃO GERAL',
    'Síntese das principais informações e métricas apresentadas no painel de controle.',
    customStyles,
  )

  pdf.addPage('a4', 'p')

  components.lineWithDate(pdf, date)

  components.subtitle(pdf, 'Visao Geral', GAP)

  components.rectangle(
    pdf,
    PADDING,
    GAP * 1.25,
    ratio(660),
    palette.primary.main,
    ratio,
    'Progresso',
    progressPercentage,
  )

  components.rectangle(
    pdf,
    PADDING * 5,
    GAP * 1.25,
    ratio(660),
    palette.primary.main,
    ratio,
    'Conformidade',
    compliance,
  )

  components.numberPage(pdf, palette.primary.main)

  var mountQuestionnaireRow = (questionnaire) => [
    [
      'Progresso de resolução do questionário ',
      Math.round(
        (questionnaire.asweredQuestions / questionnaire.totalQuestions) * 100,
      ).toString() + '%',
    ],
    [
      'Conformidade do questionário',
      Math.round(questionnaire.complianceGap) + '%',
    ],
  ]

  GAPStats.questionnaires.forEach((questionnaire, index) => {
    let startY = index === 0 ? GAP * 3 : pdf.autoTable.previous.finalY + 16

    if (questionnaire.totalQuestions <= 0) return

    components.table(
      pdf,
      [[questionnaire.name, 'Total (%)']],
      mountQuestionnaireRow(questionnaire),
      startY,
      0.5,
      PADDING,
      true,
    )

    components.numberPage(pdf, palette.primary.main)
  })

  const calculatePercentage = (statusCount, totalItems) =>
    ((statusCount / totalItems) * 100).toFixed(2)

  if (isComplete) {
    const plansStatusCounts = {}
    const totalItems = questionControls.length

    questionControls.forEach((questionControl) => {
      questionControl.questionPlanActionEvidencies.forEach((planAction) => {
        const status = planAction.status
        if (plansStatusCounts[status]) {
          plansStatusCounts[status]++
        } else {
          plansStatusCounts[status] = 1
        }
      })
    })

    delete plansStatusCounts[constants.questionControl.INITIAL_PLAN_STATUS]

    const plansList = Object.keys(plansStatusCounts).map((planStatus) => {
      const count = plansStatusCounts[planStatus]
      const percentage = calculatePercentage(count, totalItems)
      return {
        planStatus,
        count,
        percentage,
      }
    })

    const plansRows = plansList.map((plan) => [
      helpers.questionControls.status(plan.planStatus, true),
      plan.count,
      plan.percentage + '%',
    ])

    plansRows.unshift(['Status', 'Total', '%'])

    components.table(
      pdf,
      [['Planos de Ação', null, null]],
      plansRows,
      pdf.autoTable.previous.finalY + 16,
      0.5,
      PADDING,
      false,
      plansColumStyles,
    )
  }
}

export default generalInformations
