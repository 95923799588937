import React, { useState } from 'react'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { Skeleton } from '@material-ui/lab'
import { sortBy, isEmpty } from 'lodash'
import { useTheme } from '@material-ui/styles'
import {
  Container,
  Box,
  Grid,
  TablePagination,
  useMediaQuery,
  Card,
  Button,
  Tooltip,
} from '@material-ui/core'

import { IncidentsTable } from './components'
import {
  Page,
  ContentHeader,
  FilterButton,
  Filters,
  Permitted,
  DriveTour,
} from 'components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import usePermissions from 'hooks/usePermissions'

import helpers from 'helpers'
import constants from 'constants/index'
import * as service from 'service'
import useStyles from './styles'
import { routes } from 'Routes'

const IncidentsMain = () => {
  const [openTour, setOpenTour] = useState(false)

  const filter = useFilter()
  const theme = useTheme()
  const classes = useStyles()
  const permissions = usePermissions()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.incidents.get,
    {
      perPage,
      page,
      ...{ ...filter.filters },
    },
    [page, perPage, filter.filters],
  )

  const incidents = response?.data?.incidents
  const hasEditPermission = permissions.permitted(
    constants.permissions.INCIDENTS.CREATE,
  )

  const validationSteps = () => {
    if (!isLoading && !isEmpty(incidents)) {
      const steps = [...constants.incidents.INCIDENT_MAIN_DRIVER_STEPS_OBJECT]
      const typeFormSet = new Set()

      incidents.forEach((incident) => {
        const typeForm = incident?.simplifiedForm
          ? constants.incidents.SIMPLIFIED_FORM
          : constants.incidents.COMPLETE_ANPD

        if (typeForm && !typeFormSet.has(typeForm)) {
          const stepObject = helpers.incidents.typeFormDriverSteps(typeForm)

          if (stepObject) {
            steps.push(stepObject)
            typeFormSet.add(typeForm)
          }
        }
      })

      const sortedSteps = sortBy(steps, 'element')

      return sortedSteps
    }
    return []
  }

  const handleOpenTour = () => {
    const where = 'Incidentes'
    const title = 'Tour'

    const params = {
      action: 'tour-tela-incidentes',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Todos os Incidentes">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Incidentes">
          <Tooltip
            title={
              hasEditPermission
                ? 'Iniciar o tour guiado'
                : 'O tour guiado é destinado aos usuários com permissão para gerenciar incidentes'
            }
          >
            <Box pr={1}>
              <Button
                id={constants.incidents.INCIDENT_MAIN_DRIVER_STEP_1}
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                disabled={!hasEditPermission}
                fullWidth
              >
                Tutorial
              </Button>
            </Box>
          </Tooltip>
          <Permitted tag="create_incident">
            <Button
              id={constants.incidents.INCIDENT_MAIN_DRIVER_STEP_2}
              variant="contained"
              color="primary"
              onClick={() =>
                service.history.push(routes.incidentManagements.new)
              }
            >
              NOVO INCIDENTE
            </Button>
          </Permitted>
          <Box pl={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen}></FilterButton>
          </Box>
        </ContentHeader>
        <Card>
          <Box display="flex" width="100%" mt={2}>
            <Grid container>
              <IncidentsTable
                incidents={response && incidents}
                isLoading={isLoading}
                refresh={refresh}
              />
            </Grid>
          </Box>

          <Box px={2} display="flex" justifyContent="flex-end">
            {!isLoading && response ? (
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            ) : (
              <Box py="11px">
                <Skeleton variant="rect" width={200} height={30} />
              </Box>
            )}
          </Box>
        </Card>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input
            datepickerinput="true"
            label="Data de criação inicial"
            name="createdAtGt"
            data-target="createdAtLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Data de criação final"
            name="createdAtLt"
            data-target="createdAtGt"
            data-max
          />
          <input
            datepickerinput="true"
            label="Data do incidente"
            name="incidentDate"
          />
        </Filters>
      </Container>
      <DriveTour
        stepsMatrix={validationSteps()}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default IncidentsMain
