import React, { useState } from 'react'

import useBeforeunload from 'hooks/useBeforeunload'
import DataProcessContext from 'contexts/DataProcessContext'

import * as service from 'service'
import helpers from 'helpers'

const DataProcessProvider = ({ children }) => {
  const defaultDataProcess = {
    dataProcess: null,
    dataCollected: [],
  }

  useBeforeunload(() => {
    return 'Deseja realmente sair do site?'
  })

  const [departments, setDepartments] = useState([])
  const [storageLocations, setStorageLocations] = useState([])
  const [storageTimes, setStorageTimes] = useState([])
  const [discardModes, setDiscardModes] = useState([])
  const [internalAllocationModes, setInternalAllocationModes] = useState([])
  const [recoveries, setRecoveries] = useState([])
  const [shareProcesses, setShareProcesses] = useState([])
  const [protections, setProtections] = useState([])
  const [dataCollectedOptions, setDataCollectedOptions] = useState([])
  const [legalFrameworks, setLegalFrameworks] = useState([])
  const [titularCategories, setTitularCategories] = useState([])
  const [dataProcessSources, setDataProcessSources] = useState([])
  const [sourceOptions, setSourceOptions] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [lastActiveStep, setLastActiveStep] = useState(0)
  const [toNextStep, setToNextStep] = useState(false)
  const [counter, setCounter] = useState(0)
  const [data, setData] = useState(defaultDataProcess)
  const [code, setCode] = useState()
  const [laws, setLaws] = useState([])
  const [loadingReplyLiaAnswer, setLoadingReplyLiaAnswer] = useState(false)
  const [dataProcessTemplate, setDataProcessTemplate] = useState()

  const loadOptions = async (companyId) => {
    const [responseOptions, responseDepartments, responseDataProcessTemplate] =
      await Promise.all([
        service.dponet.dataProcesses.getRelationOptions({
          companyId: companyId,
        }),
        service.dponet.departments.get({
          perPage: 10000,
          order: 'name ASC',
          active: true,
        }),
        service.dponet.dataProcessTemplates.get(),
      ])

    setDataProcessTemplate(
      responseDataProcessTemplate.data?.dataProcessTemplate,
    )
    setDepartments(responseDepartments.data.departments)
    setStorageLocations(responseOptions.data.options.storageLocations)
    setStorageTimes(responseOptions.data.options.storageTimes)
    setDiscardModes(responseOptions.data.options.discardModes)
    setInternalAllocationModes(
      responseOptions.data.options.internalAllocationModes,
    )
    setRecoveries(responseOptions.data.options.recoveries)
    setShareProcesses(responseOptions.data.options.shareProcesses)
    setProtections(responseOptions.data.options.protections)
    setDataCollectedOptions(
      helpers.dataCollected.mountLabelList(
        responseOptions.data.options.dataCollectedOptions,
      ),
    )
    setLegalFrameworks(responseOptions.data.options.legalFrameworks)
    setTitularCategories(responseOptions.data.options.titularCategories)
    setDataProcessSources(responseOptions.data.options.dataProcessSources)
    setLaws(responseOptions.data.options.laws)
  }

  const reloadOptions = () => {
    setCounter(counter + 1)
  }

  return (
    <DataProcessContext.Provider
      value={{
        activeStep,
        code,
        counter,
        data,
        dataCollectedOptions,
        dataProcessSources,
        dataProcessTemplate,
        defaultDataProcess,
        departments,
        discardModes,
        internalAllocationModes,
        lastActiveStep,
        laws,
        legalFrameworks,
        loadingReplyLiaAnswer,
        loadOptions,
        protections,
        recoveries,
        reloadOptions,
        setActiveStep,
        setCode,
        setData,
        setLastActiveStep,
        setLoadingReplyLiaAnswer,
        setSourceOptions,
        setToNextStep,
        shareProcesses,
        sourceOptions,
        storageLocations,
        storageTimes,
        titularCategories,
        toNextStep,
      }}
    >
      {children}
    </DataProcessContext.Provider>
  )
}

export default DataProcessProvider
