import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import helpers from 'helpers'

const styles = ({ secondaryColor }) =>
  makeStyles((theme) => ({
    cookieIcon: {
      height: 48,
      width: 48,
      borderRadius: 30,
      backgroundColor: helpers.palette.hexToRgba(secondaryColor, 0.2),
    },
  }))

styles.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  secondaryBGColor: PropTypes.string.isRequired,
  calculatedSecondaryColor: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['default', 'medium', 'full']),
}

export default styles
