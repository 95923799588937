import React from 'react'
import { Box, Card, Link, Typography, useMediaQuery } from '@material-ui/core'

import helpers from 'helpers'

import constants from 'constants/index'
import formatters from 'helpers/formatters'

import theme from 'theme'
import useStyles from './styles'

const PaymentCard = ({ creditCard, price, nextDateAdvance }) => {
  const classes = useStyles()
  const Logo = helpers.validates.cardFlags.cardFlagDefinitionByLabel(
    creditCard?.brand,
  )
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <Card
      component={Box}
      {...(isMobile && { flexDirection: 'column', gap: 2 })}
      display="flex"
      justifyContent="space-between"
      alignItems={isMobile ? 'start' : 'center'}
      p={2}
    >
      <Box display="flex" gridGap={theme.spacing(3)} alignItems="center">
        <Box
          width={54}
          height={33.6}
          className={{
            [classes.amexBox]:
              creditCard?.brand === constants.cardFlag.AMEX_LABEL_ID,
          }}
        >
          <Logo width="100%" height="100%" />
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography>
            {creditCard?.brand} {creditCard?.maskedCard}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Expira em {creditCard?.expiration}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        {...(!isMobile && { alignItems: 'end' })}
      >
        <Typography variant="caption" color="textSecondary">
          Próxima cobrança: {formatters.date(nextDateAdvance) ?? '-'}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {price}/mês
        </Typography>
      </Box>
      {/* Sera implementado na v2 da issue 2533 */}
      {/* <Link variant="body1" underline="always" className={classes.link}>
        Cobrar novamente
      </Link> */}
    </Card>
  )
}

export default PaymentCard
