import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'
import { reverse } from 'named-urls'

import { LoadingFeedback } from 'components'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import { routes } from 'Routes'

const GoogleSignInButton = () => {
  const [loading, setLoading] = useState(false)

  const auth = useAuth()
  const snackbar = useSnackbar()
  const history = useHistory()

  const onSubmit = async (credentials) => {
    try {
      setLoading(true)

      const response = await service.dponet.auth.loginSocialProvider({
        socialProvider: 'google',
        credential: {
          token: credentials?.credential,
        },
      })

      if (response.status === 202) {
        const accessToken = response.data?.accessToken
        const user = response.data?.user

        history.push({
          pathname: reverse(routes.twoFactorAuthentication, { accessToken }),
          state: {
            email: user?.email,
          },
        })
      } else {
        await auth.loadDataWithAnimation()
      }
    } catch (error) {
      snackbar.open({
        message: defineErrorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const defineErrorMessage = (error) => {
    const response = error?.response
    const status = response?.status
    const errorMessage = response?.data?.error?.message

    if ((status === 401 || status === 422) && errorMessage) return errorMessage

    return 'Ocorreu algum erro! Tente novamente!'
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <GoogleLogin
        onSuccess={onSubmit}
        onError={() => {
          console.log('Login Failed')
        }}
        type="standard"
        shape="rectangular"
        useOneTap
      />
    </>
  )
}

export default GoogleSignInButton
