import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Typography,
  Grid,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'

import { TableDocuments } from '../../TableDocuments'

import { TableDataCollecteds } from '../../TableDataCollecteds'
import { SuggestionAlert } from 'components/RmcActionContent/components'

import useDataProcessOptions from 'hooks/useDataProcessOptions'

import styles from './styles'
import constants from 'constants/index'
import DataProcessTemplateForm from '../../DataProcessTemplateForm'

const useStyles = makeStyles(styles)

const DataCollected = ({
  dataCollecteds,
  isLoading,
  dataProcessId,
  hiddenSuggestionAlert,
  dataProcessTemplateAnswers = [],
}) => {
  const classes = useStyles()

  const dataProcessOptions = useDataProcessOptions()

  const dataProcessOptionsData = dataProcessOptions.data

  const options = dataProcessOptionsData && dataProcessOptionsData.options
  const dataCollectedOptions = options && options.dataCollectedOptions

  return (
    <>
      {isLoading ? (
        <Box
          id="loadBox"
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box mt={3} mb={2}>
            <Typography variant="h5">Dados Tratados</Typography>
            <SuggestionAlert
              hidden={hiddenSuggestionAlert}
              sectionName="Dados tratados"
              dataProcessId={dataProcessId}
              sectionTab={
                constants.suggestionChanges.TAB_VIEW_VALUE.DATA_COLLECTEDS
              }
            />
          </Box>
          <Grid container className={classes.dataCollectedContainer}>
            <Grid className={classes.dataCollectedTable} item xs={12}>
              <TableDataCollecteds
                dataCollecteds={dataCollecteds}
                dataProcessId={dataProcessId}
                classes={classes}
                dataCollectedOptions={dataCollectedOptions}
              />
            </Grid>
          </Grid>
          <Box mt={3} mb={2}>
            <Typography variant="h5">Documentos Anexados</Typography>
          </Box>
          <Grid container className={classes.dataCollectedContainer}>
            <Grid className={classes.documentsCollectedTable} item xs={12}>
              <TableDocuments classes={classes} dataProcessId={dataProcessId} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

DataCollected.propTypes = {
  dataCollecteds: PropTypes.array,
  isLoading: PropTypes.bool,
  dataProcessId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hiddenSuggestionAlert: PropTypes.bool,
}

export default DataCollected
